<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link
            to="/"
            class="brand-logo d-flex align-center"
          >
            <v-img
              :src="appLogo"
              max-height="230px"
              max-width="230px"
              alt="logo"
              contain
              class="me-3 "
            ></v-img>

          </router-link>
        </v-card-title>
        <v-row class="auth-row ma-0">
          <v-col
            lg="12"
            class="d-flex align-center auth-bg  pb-0"
          >
            <v-row>
              <v-col
                cols="12"
                sm="8"
                md="6"
                lg="12"
                class="mx-auto"
              >
                <template flat v-if="form.mainData.angabeKorrekturAngefordert">
                  <v-card flat>
                    <v-card-text>
                      <v-alert
                        dense
                        text
                        color="primary"
                        border="left"
                      >
                        Die Kampagne befindet sich momentan in Bearbeitung, weil eine Korrektur beantragt wurde.</small>
                      </v-alert>
                      <v-alert
                        dense
                        text
                        color="warning"
                        border="left"
                      >
                        <strong>Korrekturvorschlag vom {{form.mainData.zeitKorrekturAngefordertDE}}</strong><br>
                        <pre>{{form.mainData.korrekturBeschreibung}}</pre>
                      </v-alert>
                    </v-card-text>
                  </v-card>
                </template>
                <template flat v-else>
                  <template v-if="released">
                    <v-card flat>
                      <v-card-text>
                        <v-alert
                          dense
                          text
                          color="success"
                          border="left"
                        >
                          Sie haben die Kampagne erfolgreich freigegeben.
                        </v-alert>
                      </v-card-text>
                    </v-card>
                  </template>
                  <template v-else-if="form.mainData.angabeBearbeitungAbgeschlossen">
                    <v-card flat>
                      <v-card-text>
                        <v-alert
                          dense
                          text
                          color="primary"
                          border="left"
                        >
                          Sie haben die Kampagne bereits am {{form.mainData.zeitBearbeitungAbgeschlossenDE}} freigegeben.
                        </v-alert>
                        <v-alert
                          dense
                          text
                          color="primary"
                          border="left"
                          v-if="form.mainData.angabeExterneFreigabeAbgeschlossen"
                        >
                          Die Kampagne ist von der Redaktion bereits seit dem {{form.mainData.zeitExterneFreigabeAbgeschlossenDE}} freigegeben.
                        </v-alert>
                        <v-alert
                          dense
                          text
                          color="error"
                          border="left"
                          v-else
                        >
                          Die redaktionelle Freigabe steht noch aus. Diese wird angestoßen, nachdem Sie die Kampagne kostenpflichtig gebucht haben. Sie werden per E-Mail benachrichtigt, sobald die Freigabe erfolgt ist.

                        </v-alert>
                      </v-card-text>
                    </v-card>
                  </template>
                  <template v-else-if="showNotice">
                    <template v-if="noticeSaved">
                      <v-card flat>
                        <v-card-text>
                          <v-alert

                            dense
                            text
                            color="primary"
                            border="left"
                          >
                            Sie haben die Kampagne bereits am {{form.mainData.zeitBearbeitungAbgeschlossenDE}} freigegeben.
                          </v-alert>
                        </v-card-text>
                      </v-card>
                    </template>
                    <template v-else-if="loadingNoticeForm" >
                      <v-card flat>
                        <v-card-text>
                          <div class="text-center" >
                            <v-progress-circular
                              :size="50"
                              color="primary"
                              indeterminate
                            ></v-progress-circular>
                          </div>
                        </v-card-text>
                      </v-card>
                    </template>
                    <template v-else>
                      <v-form
                        ref="formNoticeRef"
                        @submit.prevent="saveNoticeData"
                      >
                        <v-card flat>
                          <v-card-title>Korrektur beantragen</v-card-title>
                          <v-card-text>
                            <v-row>
                              <v-col cols="12">
                                <v-textarea
                                  v-model="notice"
                                  outlined
                                  label="Bitte beschreiben Sie hier die gewünschten Änderungen."
                                  rows="20"
                                  hide-details="auto"
                                  placeholder="Bitte beschreiben Sie hier die gewünschten Änderungen."
                                  :rules="[validators.required]"
                                ></v-textarea>
                              </v-col>
                              <v-col cols="3">
                                <v-btn
                                  block
                                  color="success"
                                  type="submit"
                                  class="mt-6"
                                >
                                  Korrektur beantragen
                                </v-btn>
                              </v-col>
                              <v-col cols="3">
                                <v-btn
                                  block
                                  color=""
                                  type="button"
                                  class="mt-6"
                                  @click="showNotice = false"
                                >
                                  Abbrechen
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-form>
                    </template>
                  </template>
                  <template v-else>
                    <div class="text-center" v-if="loading">
                      <v-progress-circular
                        :size="50"
                        color="primary"
                        indeterminate
                      ></v-progress-circular>
                    </div>
                    <v-form
                      v-else
                      ref="formRef"
                      @submit.prevent
                    >
                      <v-row>
                        <v-col cols="3">
                          <v-btn
                            block
                            color="primary"
                            type="submit"
                            class="mt-6"
                            @click="saveData"
                          >
                            Freigeben
                          </v-btn>
                        </v-col>
                        <v-col cols="3">
                          <v-btn
                            block
                            color=""
                            type="button"
                            class="mt-6"
                            @click="showNotice = true"
                          >
                            Korrektur erwünscht
                          </v-btn>
                        </v-col>
                      </v-row>
                      <v-card flat class="mt-10">
                        <v-card-title>Freigabeprozess</v-card-title>
                        <v-card-text>
                          <v-simple-table class="mt-5">
                            <template v-slot:default>
                              <thead>
                              <tr>
                                <th class="text-left text-uppercase">Aktion Name</th>
                                <th class="text-left text-uppercase">Datenmenge</th>
                                <th class="text-left text-uppercase">Versanddatum</th>
                              </tr>
                              </thead>
                              <tbody>
                              <tr>
                                <td class="text-left">{{form.mainData.name}}</td>
                                <td>{{form.mainData.anzahlEmpfaenger}}</td>
                                <td>{{form.mainData.zeitVersandStartDE}}</td>
                              </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </v-card-text>
                      </v-card>
                      <v-row align="stretch" justify="center">
                        <v-col cols="12" md="12">
                          <v-card flat class="mt-10">
                            <v-card-title>Vorderseite</v-card-title>
                            <v-card-text>
                              <template v-if="form.mainData.adMaterial.dateiVorderseite.file.type.startsWith('image/')">
                                <v-img
                                  :src="form.mainData.adMaterial.dateiVorderseite.publicUrl.replace('%HASHKEY%',form.mainData.hashKey)"
                                ></v-img>
                              </template>

                              <template v-if="form.mainData.adMaterial.dateiVorderseite.file.type.endsWith('/pdf')">
                                <vue-pdf-with-draggable
                                  id="dateiVorderseite"
                                  :key="form.mainData.adMaterial.randomKey"
                                  :src="form.mainData.adMaterial.dateiVorderseite.publicUrl.replace('%HASHKEY%',form.mainData.hashKey)"
                                  :srcLocale="form.mainData.adMaterial.dateiVorderseite.urlLocale"
                                  :pageWidth="form.mainData.adMaterial.dateiVorderseite.dimension.width"
                                  :pageHeight="form.mainData.adMaterial.dateiVorderseite.dimension.height"
                                  :layers="getLayers({
                                    page:'dateiVorderseite',
                                    dimension: form.mainData.adMaterial.dateiVorderseite.dimension,
                                    activeToggle: false,
                                    useMovement:false,
                                    useWidthAndHeightHandle:false,
                                    useFontHandle:false,
                                    useWidthHandle:false,
                                    useRotationHandle:false,
                                    qrcodeContentHandler:false,
                                    dataPrivacyContentHandler:false,
                                  })"
                                />
                              </template>
                            </v-card-text>
                          </v-card>
                        </v-col>
                        <v-col cols="12" md="12" v-if="form.mainData.adMaterial.format != 'dina4mailing'">
                          <v-card flat class="mt-10">
                            <v-card-title>Rückseite</v-card-title>
                            <v-card-text>
                              <template v-if="form.mainData.adMaterial.dateiRueckseite.file.type.startsWith('image/')">
                                <v-img
                                  :src="form.mainData.adMaterial.dateiRueckseite.publicUrl.replace('%HASHKEY%',form.mainData.hashKey)"
                                ></v-img>
                              </template>
                              <template v-if="form.mainData.adMaterial.dateiRueckseite.file.type.endsWith('/pdf')">
                                <vue-pdf-with-draggable
                                  id="dateiRueckseite"
                                  :key="form.mainData.adMaterial.idPtWerbemittel"
                                  :src="form.mainData.adMaterial.dateiRueckseite.publicUrl.replace('%HASHKEY%',form.mainData.hashKey)"
                                  :srcLocale="form.mainData.adMaterial.dateiRueckseite.urlLocale"
                                  :pageWidth="form.mainData.adMaterial.dateiRueckseite.dimension.width"
                                  :pageHeight="form.mainData.adMaterial.dateiRueckseite.dimension.height"
                                  :layers="getLayers({
                                    page:'dateiRueckseite',
                                    dimension: form.mainData.adMaterial.dateiRueckseite.dimension,
                                    exampleRecipient : form.mainData.targetGroup.firstRecipient,
                                    activeToggle : false,
                                    useMovement:false,
                                    useWidthAndHeightHandle:false,
                                    useFontHandle:false,
                                    useWidthHandle:false,
                                    useRotationHandle:false,
                                    qrcodeContentHandler:false,
                                    dataPrivacyContentHandler:false,
                                  })"
                                />
                              </template>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-form>
                  </template>
                </template>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
    </div>
  </div>
</template>

<script>
import {ref, getCurrentInstance, onMounted, nextTick} from '@vue/composition-api'
import axios from '@axios'
import {useRouter} from '@core/utils'
import themeConfig from '@themeConfig'
import {required} from "@core/utils/validation";
import VuePdfWithDraggable from "@/views/VuePdfWithDraggable.vue";
import PushNotificationPreview from "@/views/push-notification/Preview";
import VuetifyDatetimePickerWithErrorHandling from "@/views/VuetifyDatetimePickerWithErrorHandling";
import InlineDatePicker from "@/views/InlineDatePicker";
import CostEstimateCard from "@/views/CostEstimateCard";
import useHelper from "@/views/useHelper";

export default {
  components: {
    VuePdfWithDraggable
  },
  setup(props, context){
    const {router} = useRouter()
    const formRef = ref(null)
    const formNoticeRef = ref(null)
    const form = ref(null)
    form.value = {
      mainData : {
        hashKey : router.currentRoute.params.hashKey,
        idPtKampagne : 0,
        name : '',
        anzahlEmpfaenger : 0,
        zeitVersandStart : '',
        angabeBearbeitungAbgeschlossen : 0,
        angabeKorrekturAngefordert : false,
        korrekturBeschreibung : "",
        zeitKorrekturAngefordertDE : "",
        adMaterial : {
          dateiVorderseite:{publicUrl:'',file:{type:''}},
          dateiRueckseite:{publicUrl:'',file:{type:''}},
        },
        targetGroup:{
          firstRecipient : null
        }
      }
    };

    const loading = ref(false)
    const loadingNoticeForm = ref(false)
    const released = ref(false)
    const showNotice = ref(false)
    const noticeSaved = ref(false)
    const notice = ref("")
    const vm = getCurrentInstance().proxy
    vm.$toastr.defaultTimeout = themeConfig.toastr.defaultTimeout;
    vm.$toastr.defaultPosition = themeConfig.toastr.defaultPosition;

    const {
      mergeLayerWithOverwrite
    } = useHelper()

    const getLayers = (options) => {
      let dbLayers = mergeLayerWithOverwrite(form.value.mainData.ebenenEinstellungen,options);
      dbLayers = dbLayers[options.page];
      return dbLayers;
    }

    onMounted(() => {
      axios.post('/api/unterseiten/', {
        aktion: 'fetchCampaignByHash',
        mainData: {
          hashKey: router.currentRoute.params.hashKey,
        }
      })
        .then((response) => {
          if (response.data.valid === true) {
            form.value.mainData = response.data.campaign;
            notice.value = form.value.mainData.korrekturBeschreibung;
          } else {
            response.data.errors.forEach(item => {
              let suffix = item.errorField != "" ? "(" + item.errorField + ")" : "";
              vm.$toastr.e(item.errorText, item.errorTitle + suffix);
            })
          }
          loading.value = false;
        })
        .catch((error) => {
          console.log(error)
          loading.value = true;
        })
    })

    const saveData = () => {
      const isFormValid = formRef.value.validate()
      if(!isFormValid) return
      loading.value = true;
      axios
        .post('/api/unterseiten/', {
          aktion: 'releaseCampaignByCustomer',
          mainData: {
            hashKey: router.currentRoute.params.hashKey
          }
        })
        .then(response => {
          if(response.data.valid === true){
            released.value = true;
          }
          else{
            response.data.errors.forEach(item=>{
              let suffix = item.errorField != "" ? "("+item.errorField+")" : "";
              vm.$toastr.e(item.errorText, item.errorTitle+suffix);
            })
          }
          loading.value = false;
        })
        .catch(error => {
          vm.$toastr.s(error,error);
          loading.value = true;
        })
    }

    const saveNoticeData = () => {
      const isFormValid = formNoticeRef.value.validate()
      if(!isFormValid)   {
        nextTick(() => {
          const firstErrorField = document.querySelector('.error--text');
          if (firstErrorField) {
            firstErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        });
        return
      }
      loadingNoticeForm.value = true;
      axios
        .post('/api/unterseiten/', {
          aktion: 'saveCampaignNoticeByCustomer',
          mainData: {
            hashKey: router.currentRoute.params.hashKey,
            korrekturBeschreibung: notice.value
          }
        })
        .then(response => {
          if(response.data.valid === true){
            form.value.mainData.korrekturBeschreibung = response.data.updateData.korrekturBeschreibung;
            form.value.mainData.angabeKorrekturAngefordert = response.data.updateData.angabeKorrekturAngefordert;
            form.value.mainData.zeitKorrekturAngefordertDE = response.data.updateData.zeitKorrekturAngefordertDE;
          }
          else{
            response.data.errors.forEach(item=>{
              let suffix = item.errorField != "" ? "("+item.errorField+")" : "";
              vm.$toastr.e(item.errorText, item.errorTitle+suffix);
            })
          }
          loadingNoticeForm.value = false;
          noticeSaved.value = false;
        })
        .catch(error => {
          vm.$toastr.s(error,error);
          loadingNoticeForm.value = true;
        })
    }

    return {
      form,
      formRef,
      formNoticeRef,
      released,
      saveData,
      saveNoticeData,
      appLogo: themeConfig.app.logo,
      loading,
      loadingNoticeForm,
      notice,
      showNotice,
      noticeSaved,
      getLayers,
      validators: { required },
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';

.auth-wrapper.auth-v1 .auth-inner{
  width: 90%;
  z-index: 1;
}
.auth-wrapper.auth-v1 .auth-inner p{
  /*line-height: 1.2em;*/
}
</style>
